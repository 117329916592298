export const register = async params => {
  let response = await fetch('/api/user/create', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
  response = await response.json();
  return response;
};

export const login = async params => {
  let response = await fetch(`/api/user/${params.phone}/${params.pwd}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  });
  response = await response.json();
  return response;
};

export const fetchBotList = async () => {
  let response = await fetch(`/api/bot/list`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', token: sessionStorage.getItem('token') },
  });
  response = await response.json();
  return response;
};

export const createBot = async params => {
  let response = await fetch('/api/bot/create', {
    method: 'post',
    headers: { 'Content-Type': 'application/json', token: sessionStorage.getItem('token') },
    body: JSON.stringify(params),
  });
  response = await response.json();
  return response;
};

export const updateBot = async params => {
  let response = await fetch('/api/bot/update', {
    method: 'post',
    headers: { 'Content-Type': 'application/json', token: sessionStorage.getItem('token') },
    body: JSON.stringify(params),
  });
  response = await response.json();
  return response;
};

export const deleteBot = async params => {
  let response = await fetch(`/api/bot/delete/${params.id}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', token: sessionStorage.getItem('token') },
  });
  response = await response.json();
  return response;
};

// 查询直播间状态
export const fetchLiveRoomStatus = async params => {
  let response = await fetch(`/api/bot/live_status/${params.id}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', token: sessionStorage.getItem('token') },
  });
  response = await response.json();
  return response;
};

// 启动机器人
export const startupBot = async params => {
  let response = await fetch(`/api/run_bot/${params.id}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', token: sessionStorage.getItem('token') },
  });
  response = await response.json();
  return response;
};

// 关闭机器人
export const stopBot = async params => {
  let response = await fetch(`/api/bot/stop/${params.id}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', token: sessionStorage.getItem('token') },
  });
  response = await response.json();
  return response;
};

// 获取二维码
export const fetchQRCode = async params => {
  const controller = new AbortController();
  const signal = controller.signal;

  const timeoutId = setTimeout(() => {
    controller.abort(); // 取消请求
    return null;
  }, 4000);

  let response = await fetch(`/api/bot/qr_code/${params.live_name}/${params.shopper_id}`, {
    signal,
    method: 'get',
    headers: { token: sessionStorage.getItem('token') },
  });

  clearTimeout(timeoutId);

  try {
    response = await response.blob();
    if (response.size < 5) {
      throw new Error();
    }
    console.log(response.size);
    response = URL.createObjectURL(response);
    return response;
  } catch (error) {
    console.log('尝试解析成blob失败了');
    throw new Error();
  }
};

// 获取机器人状态
export const fetchBotStatus = async params => {
  let response = await fetch(`/api/bot/status/${params.id}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', token: sessionStorage.getItem('token') },
  });
  response = await response.json();
  return response;
};

async function A() {
  setTimeout(() => {
    return 1;
  }, 1000);
}
try {
  const a = await A();
  console.log(a);
} catch (error) {
  console.log(error);
}

import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { login, register } from '../../api';
import './style.css';

const Login = () => {
  const navigate = useNavigate();

  const containerRef = useRef();

  const handleLogin = async e => {
    e.preventDefault();
    const phone = e.target[0].value;
    const pwd = e.target[1].value;

    try {
      const res = await login({ phone, pwd });
      if (res.includes('未查到')) {
        return message.error('您未注册');
      }
      if (res) {
        message.success('登录成功！');
        navigate('/robot-list');
        sessionStorage.setItem('token', res);
      } else {
        message.error('用户名或密码错误！');
      }
    } catch (error) {}
  };

  const handleRegister = async e => {
    e.preventDefault();
    const phone = e.target[0].value;
    const pwd = e.target[1].value;

    try {
      const res = await register({ phone, pwd });
      if (res.id) {
        message.success('注册成功！');
      } else {
        message.error(res);
      }
    } catch (error) {}
  };

  return (
    <div className="container" ref={containerRef}>
      <div className="forms-container">
        <div className="signin-signup">
          <form onSubmit={handleLogin} className="sign-in-form">
            <h2 className="title">登 录</h2>
            <div className="input-field">
              <i className="fas fa-mobile"></i>
              <input type="text" placeholder="手机号" />
            </div>
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input type="password" placeholder="密码" />
            </div>
            <input type="submit" value="登 录" className="btn solid" />
            {/* <p className="social-text">Or Sign in with social platforms</p>
            <div className="social-media">
              <a href="#" className="social-icon">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-google"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div> */}
          </form>
          <form onSubmit={handleRegister} className="sign-up-form">
            <h2 className="title">注 册</h2>
            <div className="input-field">
              <i className="fas fa-mobile"></i>
              <input type="text" placeholder="手机号" />
            </div>
            {/* <div className="input-field">
              <i className="fas fa-envelope"></i>
              <input type="email" placeholder="Email" />
            </div> */}
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input type="password" placeholder="密码" />
            </div>
            <input type="submit" className="btn" value="注 册" />
            {/* <p className="social-text">Or Sign up with social platforms</p>
            <div className="social-media">
              <a href="#" className="social-icon">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-google"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div> */}
          </form>
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>新用户 ?</h3>
            <p></p>
            <button
              className="btn transparent"
              id="sign-up-btn"
              onClick={() => containerRef.current.classList.add('sign-up-mode')}>
              创建账户
            </button>
          </div>
          <img src="img/log.svg" className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>已经有账户 ?</h3>
            <p></p>
            <button
              className="btn transparent"
              id="sign-in-btn"
              onClick={() => containerRef.current.classList.remove('sign-up-mode')}>
              登 录
            </button>
          </div>
          <img src="img/register.svg" className="image" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Login;

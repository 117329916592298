import { useEffect } from 'react';
import { Form, Modal, Input, message } from 'antd';
import { createBot, updateBot } from '../../../../api';

// const FormItem = Form.Item;

const CreateBotModal = props => {
  const { open, close, editData, fetchData } = props;

  const [form] = Form.useForm();
  const { getFieldsValue, resetFields, validateFields, setFieldsValue } = form;

  useEffect(() => {
    if (editData) {
      setFieldsValue({
        shopper_id: editData.shopper_id,
        live_id: editData.live_id,
        live_url: editData.live_url,
        live_doc: editData.live_doc,
        life_code: editData.life_code,
      });
    }
  }, [editData]);

  const handleCancel = () => {
    resetFields();
    close();
  };

  const handleSubmit = async () => {
    try {
      const values = await validateFields();
      if (values) {
        let response = null;
        if (editData) {
          response = await updateBot({ ...values, id: String(editData.id), status: 0 });
          if (response.id) {
            message.success('修改成功');
          } else {
            message.error('修改失败');
          }
        } else {
          response = await createBot({ ...values, id: '', status: 0 });
          if (response.id) {
            message.success('创建成功');
          } else {
            message.error('创建失败');
          }
        }
        if (response.id) {
          handleCancel();
          fetchData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal open={open} title={editData ? '编辑机器人' : '创建机器人'} onCancel={handleCancel} onOk={handleSubmit}>
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="商家号" name="shopper_id" rules={[{ required: true, message: '请输入商家号' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="商户名称" name="shopper_name" rules={[{ required: true, message: '请输入商家号' }]}>
          <Input />
        </Form.Item>
        {/* <Form.Item label="直播间id" name="live_id" rules={[{ required: true, message: '请输入直播间id' }]}>
          <Input />
        </Form.Item> */}
        <Form.Item label="直播间名称" name="live_name" rules={[{ required: true, message: '请输入直播间名称' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="直播间url" name="live_url" rules={[{ required: true, message: '请输入直播间url' }]}>
          <Input />
        </Form.Item>
        {/* <Form.Item label="生活号" name="life_code" rules={[{ required: true, message: '请输入生活号' }]}>
          <Input />
        </Form.Item> */}
        <Form.Item label="直播文档" name="live_doc" rules={[{ required: true, message: '请输入直播文档' }]}>
          <Input.TextArea style={{ width: '400px', height: '150px' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateBotModal;

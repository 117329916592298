import { useState, useEffect } from 'react';
import { Table, Button, Space, message, Modal, Image, Spin, Flex } from 'antd';
import CreateBotModal from './components/CreateBotModal';
import {
  deleteBot,
  fetchBotList,
  fetchLiveRoomStatus,
  startupBot,
  fetchQRCode,
  fetchBotStatus,
  stopBot,
} from '../../api';
import './style.css';
import { Popconfirm } from 'antd';

const { confirm } = Modal;

const RobotList = () => {
  const [spinning, setSpinning] = useState(false);

  const [data, setData] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);

  const [editData, setEditData] = useState(null);

  const [openCreateBotModal, setOpenCreateBotModal] = useState(false);

  const [openQRcodeModal, setOpenQRcodeModal] = useState(false);
  const [qrcode, setQRCode] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetchBotList();
      setData(res);
    } catch (error) {}
  };

  const handleDelete = async id => {
    try {
      const res = await deleteBot({ id });
      message.success('操作成功');
      fetchData();
    } catch (error) {}
  };

  const handleEdit = async row => {
    setEditData(row);
    setOpenCreateBotModal(true);
  };

  const stop = row => {
    confirm({
      title: '确定停止机器人?',
      async onOk() {
        console.log('OK');
        const res = await stopBot({ id: row.id });
        fetchData();
        if (res) {
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const startBot = async row => {
    confirm({
      title: '确定启动机器人?',
      async onOk() {
        console.log('OK');
        const res = await startupBot({ id: row.id });

        if (res) {
          setSpinning(true);
          setTimeout(() => getQRCode({ shopper_id: row.shopper_id, live_name: row.live_name, id: row.id }), 10000);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const getQRCode = async params => {
    try {
      const res = await fetchQRCode(params);
      console.log(res);
      if (res) {
        setSpinning(false);
        setQRCode(res);
        setOpenQRcodeModal(true);

        setTimeout(() => {
          queryBotStatus({ id: params.id });
        }, 60000);
      } else {
        throw new Error();
      }
    } catch (error) {
      setTimeout(() => {
        getQRCode(params);
      }, 1000);
    }
  };

  const queryBotStatus = async params => {
    const res = await fetchBotStatus(params);
    if (res === 2) {
      fetchData();
      return;
    }
    setTimeout(() => {
      queryBotStatus(params);
    }, 10000);
  };

  const columns = [
    {
      title: '机器人id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '商家号',
      dataIndex: 'shopper_id',
      key: 'shopper_id',
    },
    {
      title: '直播间名称',
      dataIndex: 'live_name',
      key: 'live_name',
    },
    // {
    //   title: '生活号',
    //   dataIndex: 'life_code',
    //   key: 'life_code',
    // },
    // {
    //   title: '直播间id',
    //   key: 'live_id',
    //   dataIndex: 'live_id',
    // },
    {
      title: '直播间url',
      key: 'live_url',
      dataIndex: 'live_url',
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      width: 100,
      render: v => {
        return {
          0: '未启动',
          1: '启动中',
          2: '已启动',
        }[v];
      },
    },
    {
      title: '操作',
      render: row => {
        console.log(row);
        return (
          <Space>
            {row.status !== 0 && (
              <Button type="link" onClick={() => stop(row)}>
                停止
              </Button>
            )}
            {row.status === 0 && (
              <Button type="link" onClick={() => startBot(row)}>
                启动
              </Button>
            )}
            <Button
              type="link"
              onClick={() => {
                handleEdit(row);
              }}>
              编辑
            </Button>
            <Popconfirm title="确认删除吗？" onConfirm={() => handleDelete(row.id)}>
              <Button type="link">删除</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="list-container">
      <Spin spinning={spinning} size="large" fullscreen tip="正在获取二维码, 请稍等..." />

      <div className="button-group">
        <Button type="primary" onClick={() => setOpenCreateBotModal(true)}>
          创建机器人
        </Button>
      </div>

      <Table columns={columns} dataSource={data} />

      <CreateBotModal
        open={openCreateBotModal}
        close={() => {
          setOpenCreateBotModal(false);
          setEditData(null);
        }}
        editData={editData}
        fetchData={fetchData}
      />

      <Modal
        open={openQRcodeModal}
        maskClosable={false}
        footer={() => {
          return (
            <Popconfirm
              title="请确认已经扫码再关闭，确认要关闭吗？"
              onConfirm={() => {
                setOpenQRcodeModal(false);
              }}>
              <Button>关闭</Button>
            </Popconfirm>
          );
        }}>
        {qrcode && (
          <Flex justify="center" align="center">
            <Image src={qrcode} />
          </Flex>
        )}
      </Modal>
    </div>
  );
};

export default RobotList;

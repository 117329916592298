import { createBrowserRouter } from 'react-router-dom';
import Login from '../pages/Login';
import RobotList from '../pages/RobotList';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/robot-list',
    element: <RobotList />,
  },
]);

export default router;
